@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.text-stroke {
  -webkit-text-stroke: 1.5px white;
}
/* Styles for the slider component */
.slider {
  height: 10px;
  width: 100%;
  background-color: #ddd;
  border-radius: 5px;
  margin-top: 10px;
}

/* Styles for the slider thumb */
.slider .thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #007bff;
  cursor: grab;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

/* Styles for the slider active state */
.slider .thumb.active {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.animate-marquee {
  display: flex;
  min-width: 300%;
  animation: marquee 36s linear infinite;
}

.animate-marquee:hover {
  animation-play-state: paused;
}

.custom-button-chat-widget {
  width: 54px;
  height: 54px;
  border-radius: 999rem;
  position: relative;
  border: 2px solid #893de7;
}

.custom-button-popin {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  outline: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  line-height: 60px;
  text-align: center;
  font-size: 28px;
  z-index: 999;
  background: #893de7;
  color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px #00000026;
  transition: 0.2s opacity ease-in-out;
  -webkit-transition: 0.2s opacity ease-in-out;
  right: -5px;
  bottom: -44px;
}


.trust-logo-wrapper {
  position: absolute;
  top: 1%;
  right: 1%;
  z-index: 1;
  overflow: hidden;
}


.trust-logo-wrapper img {
  width: 60px;
  height: 60px;
  display: block;
}


@media (max-width: 768px) {
  .trust-logo-wrapper img {
    width: 40px;
    height: 40px;
  }
}

.shine {
  content: '';
  position: absolute;
  top: 0;
  left: -75%;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: skewX(-20deg);
  animation: shine 2s infinite;
}

.hover-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  transition: opacity 0.3s ease;
  z-index: 2;
  border-radius: 10px;
}

.trust-logo-wrapper:hover .hover-image {
  opacity: 1;
  z-index: 1;
}

@keyframes shine {
  0% {
    left: -75%;
  }
  100% {
    left: 125%;
  }
}